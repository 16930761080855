<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Content -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <DataTable :value="schedules" dataKey="Id" v-model:expandedRows="expandedRows"
                               stripedRows scrollable :loading="loading">
                        <Column expander style="width: 5rem" />
                        <Column field="Id" header="Id"></Column>
                        <Column field="Name" header="Asset"></Column>
                        <Column field="SearchType" header="Type"></Column>
                        <Column field="Country" header="Country"></Column>
                        <Column field="Priority" header="Priority"></Column>
                        <Column field="Schedule" header="CRON Schedule"></Column>
                        <Column field="UTC_Start" header="Start From"></Column>
                        <Column field="UTC_End" header="End At"></Column>
                        <Column value="Delete" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                            <template #body="{ data }">
                                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                    <i class="pi pi-trash" @click="deleteSchedule($event, data)"/>
                                </div>
                            </template>
                        </Column>

                        <template #expansion="slotProps">
                            <div class="w-full">
                                <h3>Jobs for schedule {{ slotProps.data.Id }}</h3>
                                <DataTable :value="slotProps.data.jobs" class="w-full">
                                    <Column field="Id" header="Id" ></Column>
                                    <Column field="TaskId" header="TaskId"></Column>
                                    <Column field="UTC_Start" header="Running At" ></Column>
                                    <Column value="Delete" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                                        <template #body="{ data }">
                                            <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                                <i class="pi pi-trash" @click="deleteScheduledJob($event, data)"/>
                                            </div>
                                        </template>
                                    </Column>
                                </DataTable>
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "ScheduledJobs",

    data() {
        return {
            loading: false,
            schedules: [],
            jobs: [],

            expandedRows: [],
        }
    },

    mounted() {
        this.getScheduledJobs()
    },

    methods: {
        getScheduledJobs() {
            this.loading = true
            this.$factory.schedule.getSchedulesAndJobs().then((response) => {
                this.schedules = response.schedules

                // Convert asset Ids to names from store
                let activeAssets = this.$store.getters.getActiveAssetData
                let inactiveAssets = this.$store.getters.getInactiveAssetData
                let allAssets = activeAssets.concat(inactiveAssets)

                this.schedules.forEach((schedule) => {
                    schedule.Name = allAssets.find((asset) => asset.id === schedule.AssetId).name
                })

                this.jobs = response.jobs;

                // Add jobs to schedules
                this.schedules.forEach((schedule) => {
                    schedule.jobs = this.jobs.filter((job) => job.ScheduleId === schedule.Id)
                })
            }).catch((err) => {
                console.error(err)
                this.$emitter.emit("bad-toast", "Failed to get scheduled jobs")
            }).finally(() => {
                this.loading = false
            })
        },

        deleteSchedule(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.schedule.deleteSchedule(data.Id).then(() => {
                        this.getScheduledJobs();
                        this.$emitter.emit('good-toast', 'Schedule ' + data.Id + ' deleted');
                    }).catch(error => {
                        console.error(error);
                        this.$emitter.emit('bad-toast', 'Failed to delete schedule ' + data.Id);
                    })
                },
            });

        },

        deleteScheduledJob(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this entry?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$factory.schedule.deleteScheduledJob(data.Id).then(() => {
                        this.getScheduledJobs();
                        this.$emitter.emit('good-toast', 'Scheduled job ' + data.Id + ' deleted');
                    }).catch(error => {
                        console.error(error);
                        this.$emitter.emit('bad-toast', 'Failed to delete scheduled job ' + data.Id);
                    })
                },
            });
        },
    }
}
</script>

<style scoped>

</style>
